import { Col, Row, Container } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { useTranslation, Trans } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/home/main.css';

function Main() {
  return (
    <div className="offwhite" id="main-wrap">
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <Image cloudName="praja" crop="scale" className="w-100" secure="true" publicId="phone_inrgze" dpr="auto" responsive width="auto" crop="scale" alt="App example">
              <Transformation quality="auto" crop="scale"  />
              <Transformation fetchFormat="auto" />
            </Image>
          </Col>
          <Col className="d-flex align-items-center"  sm={12} md={6}>
            <div>
              <h1 className="title">
                <Trans>
                  Travel cashlessly.
                </Trans>
              </h1>
              <h2 className="subtitle">
                <Trans>
                  A wallet, public transport pass, currency exchange and more, all in one app. Pay for anything, all from your phone.
                </Trans>
              </h2>
              <a href="#contactless" className="text-decoration-none">
                <button class="custom-btn"> 
                  <Trans>
                    learn more 
                  </Trans>
                </button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Main;
