import { Form, Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from "react-i18next";
import Navibar from './navbar.js';
import Footer from './footer.js';
import Whatsapp from '../images/whatsapp.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/contactForm.css';

function ContactForm() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <Container>
      <Row className="pb-4 pt-4">
        <Col>
          <h1>
            <Trans>
              Contact us
            </Trans>
          </h1>
          <br />
          <Form>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder={t("Subject")} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="email" placeholder={t("name@example.com")} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control as="textarea" placeholder={t("Your message")} rows={3} />
            </Form.Group>
            <button class="custom-btn">
              <Trans>
                Submit
              </Trans>
            </button>
          </Form>
        </Col>
        <Col>
          <br />
          <span>
            <a href={"https://api.whatsapp.com/send?phone=+351963877688&text=" + t("Hello!%20%0AHow%20can%20we%20help%20you?")}>
              <img src={Whatsapp} alt={t("Whatsapp Icon")} id="svgShadow" />
            </a>
            <br /><br />
            <Trans>
              Or contact us at info@praja.pt
            </Trans>
          </span>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactForm;
