import { Helmet } from "react-helmet";
import Navibar from './components/navbar.js';
import Footer from './components/footer.js';
import ContactForm from './components/contact.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation, Trans } from "react-i18next";

function Contact() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="App min-h-100">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Praja - {t("Contact")}</title>
      </Helmet>
      <Navibar />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Contact;
