import { Col, Row, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContactlessOutlined } from '@material-ui/icons';
import { useTranslation, Trans } from "react-i18next";
import '../../css/home/contactless.css';

function Contactless() {
  return (
    <div className="p-5 m-4 mb-5 mt-5" id="contactless">
      <Container>
        <Row className="c-reverse">
          <Col className="d-flex align-items-center c-pt-2">
            <div>
              <h1 className="title">
                <Trans>
                  Contactless payments and transport tickets
                </Trans>
              </h1>
              <h2 className="subtitle">
                <Trans>
                  Contactlessly pay at restaurants, stores and on public transport all from your phone using your e-wallet.
                </Trans>
              </h2>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <ContactlessOutlined style={{fontSize:"12rem"}} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contactless;
