import React, { useState } from "react";
import { Col, Row, Container, Modal, Button } from 'react-bootstrap';
import { useTranslation, Trans } from "react-i18next";
import { ContactlessOutlined } from '@material-ui/icons'
import { ReactSVG } from 'react-svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/home/betasignup.css';

function Beta() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="pb-5 mt-5 offwhite" id="beta">
      <center>
        <h1 className="title">
          <Trans>
            Sign up to test the beta
          </Trans>
        </h1>
        <button class="custom-btn" onClick={handleShow}> 
          <Trans>
            learn more
          </Trans>
        </button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>
                Open Beta hasn't begun yet!
              </Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-4 pb-4">
            <Trans>
              Open Beta will begin Winter 2022, we look forward to working with you
            </Trans>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <Trans>
                Close
              </Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </center>
    </div>
  );
}

export default Beta;
