import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { ContactlessOutlined } from '@material-ui/icons';
import { useTranslation, Trans } from "react-i18next";
import { ReactSVG } from 'react-svg';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/footer.css';


function Footer() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="offwhite">
      <Container id="footer">
        <Row className="pb-3 pt-3">
          <Col xs={6} sm={3}>
            <div className="footer-title mb-3">
              <Trans>
                Features
              </Trans>
            </div>
            <div className="mb-2">
              <Link className="text-muted text-decoration-none" to="/gifts">
                <Trans>
                  Gifts
                </Trans>
              </Link>
            </div>
            <div>
              <Link className="text-muted text-decoration-none" to="/payments">
                <Trans>
                  Payments
                </Trans>
              </Link>
            </div>
          </Col>
          <Col xs={6} sm={3}>
            <div className="footer-title mb-3">
              <Trans>
                Resources
              </Trans>
            </div>
            <div className="mb-2">
              <Link className="text-muted text-decoration-none" to="/fees">
                <Trans>
                  Fees
                </Trans>
              </Link>
            </div>
            <div>
              <Link className="text-muted text-decoration-none" to="/business/">
                <Trans>
                  For Business
                </Trans>
              </Link>
            </div>
          </Col>
          <Col xs={6} sm={3} className="c-pt-4">
            <div className="footer-title mb-3">
              <Trans>
                Company
              </Trans>
            </div>
            <div className="mb-2">
              <Link className="text-muted text-decoration-none" to="/contact">
                <Trans>
                  Contact
                </Trans>
              </Link>
            </div>
            <div>
              <Link className="text-muted text-decoration-none" to="/about">
                <Trans>
                  About Us
                </Trans>
              </Link>
            </div>
          </Col>
          <Col xs={6} sm={3} className="c-pt-4">
            <div className="footer-title mb-3">
              Social
            </div>
            <div className="mb-2">
              <a href="" className="text-muted text-decoration-none">Twitter</a>
            </div>
            <div>
              <a className="footer-link text-muted text-decoration-none" href="https://www.linkedin.com/company/praja-pt">LinkedIn</a>
            </div>
          </Col>
        </Row>
        <div id="footer-logo">
          Praja.
        </div>
      </Container>
    </div>
  );
}

export default Footer;
