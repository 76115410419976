import React, { useState } from 'react';
import { Col, Row, Container, OverlayTrigger, Tooltip, Collapse } from 'react-bootstrap';
import { useTranslation, Trans } from "react-i18next";
import InfoIcon from '@material-ui/icons/Info';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/fees/main.css';

const transactionTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans>
      Any payment you make to anyone else.
    </Trans>
  </Tooltip>
);
const depositTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans>
      Deposits from your Praja account to your bank account using SEPA. Only available for business accounts as of
    </Trans>
     {(new Date().getFullYear())}.
  </Tooltip>
);
const sepaTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans>
      Deposits from your bank to your Praja account using SEPA.
    </Trans>
  </Tooltip>
);
const maintanenceTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans>
      Praja isn't a bank, keep money in your account at no cost!
    </Trans>
  </Tooltip>
);

function Main() {
  const [open, setOpen] = useState(false);
  return (
    <div className="offwhite" id="main-wrap">
      <Container>
        <div className="text-center text-muted lead mb-3 mt-2">
          <Trans>
            Main Fees
          </Trans>
        </div>
        <Row>
          <Col className="text-center border pt-3 pb-4 border-right-0">
            <span className="display-1">0</span>
            <span className="lead">%</span>
            <br />
            <Trans>Transaction fees</Trans>&nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={transactionTooltip}
            >
              <InfoIcon style={{fontSize: "1rem", marginTop: '-.5%'}}/>
            </OverlayTrigger>
          </Col>
          <Col className="text-center border pt-3 pb-3 border-right-0">
            <span className="display-1">0</span>
            <span className="lead">%</span>
            <br />
            <Trans>Outbound SEPA deposit fees</Trans>&nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={depositTooltip}
            >
              <InfoIcon style={{fontSize: "1rem", marginTop: '-.5%'}}/>
            </OverlayTrigger>
          </Col>
          <Col className="text-center border pt-3 pb-3 border-right-0">
            <span className="display-1">0</span>
            <span className="lead">%</span>
            <br />
            <Trans>Inbound SEPA deposit fees</Trans>&nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={sepaTooltip}
            >
              <InfoIcon style={{fontSize: "1rem", marginTop: '-.5%'}}/>
            </OverlayTrigger>
          </Col>
          <Col className="text-center border pt-3 pb-3">
            <span className="display-1">0</span>
            <span className="lead">€</span>
            <br />
            <Trans>Maintenance fees</Trans>&nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={maintanenceTooltip}
            >
              <InfoIcon style={{fontSize: "1rem", marginTop: '-.5%'}}/>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Collapse in={open}>
            <div id="collapsed-fees" className="border border-top-0 p-3">
              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
              terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
              labore wes anderson cred nesciunt sapiente ea proident.
            </div>
          </Collapse>
          <div
            className="d-flex justify-content-center pointer"
            onClick={() => setOpen(!open)}
            aria-controls="collapsed-fees"
            aria-expanded={open}
          >
            <div className="border border-top-0 py-1 px-2 position-absolute">
              <Trans>More fees & details</Trans>
            </div>
          </div>
        </Row>

      </Container>
    </div>
  );
}

export default Main;
