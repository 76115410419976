import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './home.js';
import Contact from './contact.js';
import Fees from './fees.js';
import About from './about.js';

function App() {
  return (
    <Router>

      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/fees">
          <Fees />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
