import { Col, Row, Container } from 'react-bootstrap';
import { ContactlessOutlined } from '@material-ui/icons'
import { useTranslation, Trans } from "react-i18next";
import { Image, Transformation } from 'cloudinary-react';
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactSVG } from 'react-svg';
import '../../css/home/green.css';

function Green() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="p-5 mb-5 mt-5 offwhite" id="green">
      <Container>
        <Row>
          <Col sm={12} md={6} className="d-flex align-items-center justify-content-center position-relative">
            <Fade left> {/*BUG: alt="Ticket machines with littered receipts in Lisbon" seems to change appearance*/}
              <Image cloudName="praja" crop="scale" className="w-100 shadow rounded" secure="true" publicId="bilheteira_obpstx" dpr="auto" responsive width="auto" crop="scale" >
                <Transformation quality="auto" crop="scale"  />
                <Transformation fetchFormat="auto" />
              </Image>
              <div style={{bottom: 5, left: '5%'}} className="position-absolute opacity-low px-2 rounded shadow-sm">
                <span className="hide-small">
                  <Trans>
                    Cais do Sodré station, Lisbon
                  </Trans>
                </span>
                <span className="hide-big">
                  <Trans>
                    Cais do Sodré, Lisbon
                  </Trans>
                </span>
              </div>
            </Fade>
          </Col>
          <Col sm={12} md={6} className="d-flex align-items-center c-pt-2">
            <div>
              <h1 className="title">
                <Trans>
                  Help the planet, and save paper
                </Trans> 
              </h1>
              <h2 className="subtitle">
                <Trans>
                  With e-reciepts you'll never need to waste paper using Praja. Skip ticket lines, and go straight to your destination.
                </Trans>
              </h2>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Green;
