import { Helmet } from "react-helmet";
import Navibar from './components/navbar.js';
import Main from './components/home/main.js';
import Contactless from './components/home/contactless.js';
import { useTranslation, Trans } from "react-i18next";
import Green from './components/home/green.js';
import Cash from './components/home/cash.js';
import Beta from './components/home/betasignup.js';
import Footer from './components/footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/home.css';

function Home() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="App min-h-100">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Praja - {t("Home")}</title>
      </Helmet>
      <div className="min-vh-100 offwhite">
        <Navibar />
        <Main />
      </div>
      <Contactless />
      <Green />
      <Cash />
      <Beta />
      <Footer />
    </div>
  );
}

export default Home;
