import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "Contact us": "Contact us",
          "Portuguese": "Portuguese",
          "English": "English",
          "French": "French",
          "Submit": "Submit",
          "name@example.com": "name@example.com",
          "For Business": "For Business",
          "Contact": "Contact",
          "Company": "Company",
          "About Us": "About Us",
          "Gifts": "Gifts",
          "Fees": "Fees",
          "Payments": "Payments",
          "Features": "Features",
          "Cais do Sodré station, Lisbon": "Cais do Sodré station, Lisbon",
          "Cais do Sodré, Lisbon": "Cais do Sodré, Lisbon",
          "Your message": "Your message",
          "Subject": "Subject",
          "Travel cashlessly.": "Travel cashlessly.",
          "Sign up to test the beta": "Sign up to test the beta",
          "learn more": "learn more",
          "Home": "Home",
          "Or contact us at info@praja.pt": "Or contact us at info@praja.pt",
          "Whatsapp Icon": "Whatsapp Icon",
          "Hello!%20%0AHow%20can%20we%20help%20you?": "Hello!%20%0AHow%20can%20we%20help%20you?",
          "Resources": "Resources",
          "A wallet, public transport pass, currency exchange and more, all in one app. Pay for anything, all from your phone." : "A wallet, public transport pass, currency exchange and more, all in one app. Pay for anything, all from your phone.",
          "Main Fees": "Main Fees",
          "Transaction fees": "Transaction fees",
          "Outbound SEPA deposit fees": "Outbound SEPA deposit fees",
          "Maintenance fees": "Maintenance fees",
          "More fees & details": "More fees & details",
          "Any payment you make to anyone else.": "Any payment you make to anyone else",
          "Deposits from your Praja account to your bank account using SEPA. Only available for business accounts as of": "Deposits from your Praja account to your bank account using SEPA. Only available for business accounts as of",
          "Close": "Close",
          "Open Beta hasn't begun yet!": "Open Beta hasn't begun yet!",
          "Open Beta will begin Winter 2022, we look forward to working with you": "Open Beta will begin Winter 2022, we look forward to working with you",
          "Save money": "Save money",
          "Find nearby discounts, offers, and events.": "Find nearby discounts, offers, and events.",
          "Help the planet, and save paper": "Help the planet, and save paper",
          "Contactless payments and transport tickets": "Contactless payments and transport tickets",
          "Contactlessly pay at restaurants, stores and on public transport all from your phone using your e-wallet.": "Contactlessly pay at restaurants, stores and on public transport all from your phone using your e-wallet.",
          "With e-reciepts you'll never need to waste paper using Praja. Skip ticket lines, and go straight to your destination.": "With e-reciepts you'll never need to waste paper using Praja. Skip ticket lines, and go straight to your destination.",
          "Deposits from your bank to your Praja account using SEPA.": "Deposits from your bank to your Praja account using SEPA.",
          "Praja isn't a bank, keep money in your account at no cost!": "Praja isn't a bank, keep money in your account at no cost!"
        }
      },
      pt: {
        translations: {
          "Contact us": "Contacte-nos",
          "Portuguese": "Português",
          "English": "Inglês",
          "French": "Francês",
          "Submit": "Enviar",
          "name@example.com": "nome@exemplo.pt",
          "For Business": "Empresas",
          "Contact": "Contactos",
          "Company": "Empresa",
          "About Us": "Sobre nós",
          "Gifts": "Ofertas",
          "Fees": "Taxas",
          "Payments": "Pagamentos",
          "Features": "Funções",
          "Cais do Sodré station, Lisbon": "Estação de Cais do Sodré, Lisboa",
          "Cais do Sodré, Lisbon": "Cais do Sodré, Lisboa",
          "Your message": "Descreva a tua questão ou problema",
          "Subject": "Assunto",
          "Travel cashlessly.": "Viaje mais leve.",
          "Sign up to test the beta": "Inscreve-te como testador Beta",
          "learn more": "saber mais",
          "Home": "Ínicio",
          "Or contact us at info@praja.pt": "Ou contact-nos através do nosso e-mail info@praja.pt",
          "Whatsapp Icon": "Ícone do Whatsapp",
          "Hello!%20%0AHow%20can%20we%20help%20you?": "Olá!%20%0AComo%20é%20que%20te%20posso%20ajudar?",
          "Resources": "Recursos",
          "A wallet, public transport pass, currency exchange and more, all in one app. Pay for anything, all from your phone." : "Uma carteira digital, passe de transporte público, casa de câmbio e muito mais, tudo em um só app. Pague por tudo com o teu telefone.",
          "Main Fees": "Taxas Principais",
          "Transaction fees" : "Taxas de transação",
          "Outbound SEPA deposit fees": "Transferências SEPA a partir da Praja",
          "Inbound SEPA deposit fees": "Transferências SEPA para a Praja",
          "Maintenance fees": "Taxas de manutenção",
          "More fees & details": "Outras taxas e informação",
          "Any payment you make to anyone else.": "Qualquer pagamento para uma empresa/individual.",
          "Deposits from your Praja account to your bank account using SEPA. Only available for business accounts as of": "Depositos da tua conta para o teu banco usando o SEPA. Disponível apenas para contas empresariais a partir de ",
          "Close": "Fechar",
          "Open Beta hasn't begun yet!": "O open beta ainda não começou",
          "Open Beta will begin Winter 2022, we look forward to working with you": "O Open Beta começará no inverno de 2022, estamos ansiosos para trabalhar com tigo!",
          "Save money": "Poupe dinheiro",
          "Find nearby discounts, offers, and events.": "Descobre eventos, ofertas, e discontos à tua volta.",
          "Help the planet, and save paper": "Ajude o planeta, poupa papel",
          "Contactless payments and transport tickets": "Pagamentos sem contacto e bilhetes de transporte",
          "Contactlessly pay at restaurants, stores and on public transport all from your phone using your e-wallet.": "Pague sem contacto em restaurantes, lojas e nos transportes públicos, tudo a partir do seu telefone, usando a tua carteira digital.",
          "With e-reciepts you'll never need to waste paper using Praja. Skip ticket lines, and go straight to your destination.": "Com e-reciepts nunca precisarás de desperdiçar papel usando o Praja. Salte as linhas de bilhetes, e vá directamente para o teu destino.",
          "Deposits from your bank to your Praja account using SEPA.": "Depósitos do teu banco à tua conta Praja utilizando a SEPA.",
          "Praja isn't a bank, keep money in your account at no cost!": "Praja não é um banco, mantenha dinheiro na tua conta sem custos!"
        }
      },
      fr: {
        translations: {
          "French": "Français",
          "English": "Anglais",
          "Portuguese": "Portugais",
          "Submit": "Soumettre",

          "name@example.com": "nom@exemple.com",
          "For Business": "Pour le business",
          "Contact": "Contact",
          "Company": "Société",
          "About Us": "À propos de nous",
          "Gifts": "Cadeaux",
          "Fees": "Frais",
          "Payments": "Paiements",
          "Features": "Caractéristiques",
          "Cais do Sodré station, Lisbon": "Gare de Cais do Sodré, Lisbonne",
          "Cais do Sodré, Lisbon": "Cais do Sodré, Lisbonne",
          "Your message": "Votre message",
          "Subject": "Sujet",
          "Travel cashlessly.": "Voyagez sans comptant.",
          "Sign up to test the beta": "Inscrivez-vous pour tester la bêta",
          "learn more": "En apprendre plus",
          "Home": "Accueil",
          "Or contact us at info@praja.pt": "Ou contactez-nous à info@praja.pt",
          "Whatsapp Icon": "Icône Whatsapp",
          "Hello!%20%0AHow%20can%20we%20help%20you?": "Bonjour!%20%0AComment%20pouvons-nous%20vous%20aider?",
          "Resources": "Ressources",
          "A wallet, public transport pass, currency exchange and more, all in one app. Pay for anything, all from your phone." : "Un portefeuille, un pass pour les transports en commun, un bureau de change et plus encore, le tout dans une seule application. Payez n'importe quoi, le tout depuis votre téléphone.",
          "Main Fees": "Frais principaux",
          "Transaction fees": "Frais de transaction",
          "Outbound SEPA deposit fees": "Frais de dépôt SEPA sortant",
          "Maintenance fees": "Frais d'entretien",
          "More fees & details": "Plus de frais et de détails",
          "Any payment you make to anyone else.": "Tout paiement que vous effectuez à quelqu'un d'autre.",
          "Deposits from your Praja account to your bank account using SEPA. Only available for business accounts as of": "Dépôts de votre compte Praja sur votre compte bancaire via SEPA. Uniquement disponible pour les comptes professionnels à partir de",
          "Close": "Fermé",
          "Open Beta hasn't begun yet!": "La bêta ouverte n'a pas encore commencé!",
          "Open Beta will begin Winter 2022, we look forward to working with you": "La bêta ouverte débutera à l'hiver 2022, nous sommes impatients de travailler avec vous",
          "Save money": "Économiser de l'argent",
          "Find nearby discounts, offers, and events.": "Trouver des réductions, des offres et des événements à proximité.",
          "Help the planet, and save paper": "Aidez la planète et économisez du papier",
          "Contactless payments and transport tickets": "Paiements et titres de transport sans contact",
          "Contactlessly pay at restaurants, stores and on public transport all from your phone using your e-wallet.": "Payez sans contact dans les restaurants, les magasins et dans les transports en commun depuis votre téléphone à l'aide de votre porte-monnaie électronique.",
          "With e-reciepts you'll never need to waste paper using Praja. Skip ticket lines, and go straight to your destination.": "Avec les reçus électroniques, vous n'aurez jamais besoin de gaspiller du papier en utilisant Praja. Évitez les files d'attente et rendez-vous directement à votre destination.",
          "Deposits from your bank to your Praja account using SEPA.": "Dépôts de votre banque sur votre compte Praja via SEPA.",
          "Praja isn't a bank, keep money in your account at no cost!": "Praja n'est pas une banque, gardez de l'argent sur votre compte sans frais!"
        }
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
