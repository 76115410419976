import React, { useState, useEffect, useSelector, useRef } from 'react';
import { Navbar, Container, NavDropdown, Nav, Dropdown } from 'react-bootstrap';
import { useTranslation, Trans } from "react-i18next";
import Twemoji from 'react-twemoji';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navbar.css';
import i18n from "i18next";

function Navibar() {
  const { t, i18n } = useTranslation();
  var langLogo = "https://twemoji.maxcdn.com/v/13.1.0/72x72/1f1ec-1f1e7.png";

  function langCheck() {
    if (i18n.language == "pt") {
      return "https://twemoji.maxcdn.com/v/13.1.0/72x72/1f1f5-1f1f9.png";
    } else if (i18n.language == "en") {
      return "https://twemoji.maxcdn.com/v/13.1.0/72x72/1f1ec-1f1e7.png";
    } else if (i18n.language == "fr") {
      return "https://twemoji.maxcdn.com/v/13.1.0/72x72/1f1eb-1f1f7.png";
    } else {
      return langLogo;
    }
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setImg(langCheck())
  };

  const [img, setImg] = useState(langCheck());
  // https://stackoverflow.com/questions/43630991/by-clicking-text-how-to-change-clicked-text-to-another-text-in-react-js

  return (
    <Navbar className="pt-3 pb-3">
      <Container>
        <Navbar.Collapse id="basic-navbar-nav">
          <Navbar.Brand as={Link} to="/">Praja.</Navbar.Brand>

          <Nav className="center-navbar">
            <Nav.Link href="/features">
              <Trans>
                Features
              </Trans>
            </Nav.Link>
            <Nav.Link href="/business/">
              <Trans>
                For Business
              </Trans>
            </Nav.Link>
          </Nav>

          <Nav className="ms-auto">
            <Dropdown>
              <Dropdown.Toggle variant="btn-link" id="navdropdown">
                <img draggable="false" class="twemoji" alt="🇵🇹" src={img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage("pt")}>
                  <Twemoji options={{ className: 'twemoji' }}>
                    🇵🇹 &nbsp;
                    <Trans>
                      Portuguese
                    </Trans>
                  </Twemoji>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("en")}>
                  <Twemoji options={{ className: 'twemoji' }}>
                    🇬🇧 &nbsp;
                    <Trans>
                      English
                    </Trans>
                  </Twemoji>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("fr")}>
                  <Twemoji options={{ className: 'twemoji' }}>
                    🇫🇷 &nbsp;
                    <Trans>
                      French
                    </Trans>
                  </Twemoji>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navibar;
