import { Col, Row, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AttachMoney } from '@material-ui/icons';
import '../../css/home/cash.css';
import { useTranslation, Trans } from "react-i18next";

function Cash() {
  return (
    <div className="p-5 m-4 mb-5 mt-5" id="cash">
      <Container>
        <Row className="c-reverse">
          <Col className="d-flex align-items-center c-pt-2">
            <div>
              <h1 className="title">
                <Trans>
                  Save money
                </Trans>
              </h1>
              <h2 className="subtitle">
                <Trans>
                  Find nearby discounts, offers, and events.
                </Trans>
              </h2>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <AttachMoney style={{fontSize:"12rem"}} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cash;
