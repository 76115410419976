import { Helmet } from "react-helmet";
import Navibar from './components/navbar.js';
import Footer from './components/footer.js';
import Main from './components/fees/main.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation, Trans } from "react-i18next";

function Fees() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="App min-h-100">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Praja - {t("Fees")}</title>
      </Helmet>
      <Navibar />
      <Main />
      <Footer />
    </div>
  );
}

export default Fees;
